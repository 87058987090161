import * as React from "react";
import { CustomerNotifications } from "src/components/shared/CustomerNotifications";
import { CustomerNotificationsWrapperFlexModuleResult, CustomerNotificationsWrapperProps } from "./typings";
import { CustomerNotificationsInPage } from "src/components/shared/StorefrontWizard/TravelAdvisory/CustomerNotificationsInPage";
import { UitkSpacing } from "uitk-react-spacing";
import { withStores } from "stores";
import { observer } from "mobx-react";
import { useProvidedState } from "@shared-ui/provided-state-context";
import { CustomerNotificationStateEvent } from "@shared-ui/customer-notifications";
import { UitkHeading } from "uitk-react-text";

export const CustomerNotificationsWrapper = withStores("flexModuleModelStore")(
  observer((props: CustomerNotificationsWrapperProps) => {
    const { flexModuleModelStore, templateComponent } = props;

    /* istanbul ignore if */
    if (!templateComponent || !flexModuleModelStore) {
      return null;
    }

    const {
      metadata: { id },
    } = templateComponent;

    const model = flexModuleModelStore.getModel(id) as CustomerNotificationsWrapperFlexModuleResult | null;
    const [state] = useProvidedState();

    if (!model) {
      return null;
    }

    const { location, className, reasonsToBelieve, reasonsToBelieveVersion, title } = model;

    if (!location) {
      return null;
    }

    const shouldDisplayTitle =
      title &&
      state?.customerNotificationControlState?.[location]?.state ===
        CustomerNotificationStateEvent.CustomerNotificationInitialized;

    const wrapperTitle = shouldDisplayTitle && (
      <UitkSpacing padding={{ blockend: "three" }}>
        <UitkHeading tag="h2" size={3} data-testid="customer-notifications-wrapper-title">
          {title}
        </UitkHeading>
      </UitkSpacing>
    );

    const customerNotificationsInPage = (
      <div className="ReasonsToBelieveWrapper" data-testid="reasons-to-believe-wrapper">
        {wrapperTitle}
        <CustomerNotificationsInPage reasonsToBelieveVersion={reasonsToBelieveVersion} />
      </div>
    );

    return reasonsToBelieve && location === "IN_PAGE" ? (
      customerNotificationsInPage
    ) : (
      <div className="CustomerNotificationsWrapper" data-testid="customer-notifications-wrapper">
        {wrapperTitle}
        <CustomerNotifications location={location} className={className} />
      </div>
    );
  })
);

export default CustomerNotificationsWrapper;
